import * as React from "react";
import { Admin, Resource, usePermissions } from "react-admin";
import { createMuiTheme } from "@material-ui/core/styles";
import jsonServerProvider from "ra-data-json-server";
import authprovider from "./authprovide";
import { Layout } from "./components/Layout";
import MyLoginPage from "./components/Layout/Login";
import { getTropicalResources, getHeinekenResources, getNutriPuraResources, getGeneralResources, getStandardResources } from './Resources';

const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#256F44",
      light: "#1d118c",
    },
    secondary: {
      light: "#01579B",
      main: "#01579B",
    },
    default: {
      light: "#DC004E",
      main: "#DC004E",
    },
  },
  typography: {
    fontFamily: ["Cambay", "sans-serif"].join(","),
  },
});

const initialState = {
  admin: { ui: { sidebarOpen: true, viewVersion: 0 } },
};

function App() {
  const url = window.location.hostname;
  var url_splt = url.split(".");
  return (
    <Admin
      initialState={initialState}
      theme={theme}
      loginPage={MyLoginPage}
      dataProvider={dataProvider}
      authProvider={authprovider}
      // dashboard={Dasboard}
      layout={Layout}
    >
      {(permissions) => {
        const permission = JSON.parse(permissions);
        let resources = [];

        switch (url_splt[0]) {
          case "sementestropical":
            resources = getTropicalResources(permission);
            break;
          case "heineken":
            resources = getHeinekenResources();
            break;
          case "nutripura":
            resources = getNutriPuraResources();
            break;
          default:
            resources = getStandardResources();
            break;
        }

        return [...resources, ...getGeneralResources(permission)];
      }}
    </Admin>
  );
}

export default App;
