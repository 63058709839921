import React from 'react';
import {Portal, Snackbar} from '@material-ui/core';
import {Alert} from '@material-ui/lab';

export default class AlertSnackBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            type: props.type,
            message: props.message,
        }
    }

    render() {
        const {onClose} = this.props
        return (
            <Portal>
                <Snackbar
                    style={{height: 'auto', lineHeight: '28px', whiteSpace: 'pre-line', zIndex: 1500}}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                    open={this.state.open}
                    autoHideDuration={5000}
                    onClose={onClose}
                >
                    <Alert elevation={6} variant="filled" onClose={onClose} severity={this.state.type}>
                        {this.state.message}
                    </Alert>
                </Snackbar>
            </Portal>
        )
    }
}
