import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import api from "../../../service/api";
import SuccessAlert from "../../alertas/SuccessAlert";
import NewEnterprise from "./newEnterprise";
import { validatePermission } from "../../../libs/permissions";

class Usuario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "id", field: "id", hidden: true },
        { title: "Nome", field: "name" },
        { title: "CNPJ", field: "cnpj" },
        // { title: 'Tipo', field: 'tipo' },
        // {
        //     title: 'Status', field: 'status', lookup: { 0: 'Inativo', 1: 'Ativo' },
        // },
      ],
      data: [],
      loading: false,
      novoUsuario: false,
      messageSuccess: "",
      userId: null,
    };
  }
  componentDidMount() {
    validatePermission();
    this.getEnterprise();
  }

  getEnterprise = () => {
    const url = window.location.hostname;
    var url_split = url.split(".");
    api.get(`/enterprises`).then((enterprise) => {
      this.setState({ data: enterprise.data });
    });
  };

  saveEnterprise = () => {
    this.getEnterprise();
    this.setState({
      newEnterprise: false,
      messageSuccess: "Empresa salva com sucesso",
    });
  };
  savePassword = () => {
    this.getUsuario();
    this.setState({
      alterPassword: false,
      messageSuccess: "Senha Alterada com sucesso",
      userId: null,
    });
  };
  render() {
    return (
      <Paper>
        {this.state.newEnterprise && (
          <NewEnterprise save={() => this.saveEnterprise()} />
        )}
        {/* {this.state.novoUsuario && <NovoUsuario close={() => this.setState({ novoUsuario: false })} save={() => this.saveUsuario()} />} */}
        {/* {this.state.alterPassword && <AlterPassword user={this.state.userId} close={() => this.setState({ alterPassword: false })} save={() => this.savePassword()} />} */}
        <SuccessAlert
          open={this.state.messageSuccess !== ""}
          message={this.state.messageSuccess}
          onClose={() => this.setState({ messageSuccess: "" })}
        />
        <MaterialTable
          title="Empresas"
          columns={this.state.columns}
          data={this.state.data}
          isLoading={this.state.loading}
          actions={[
            {
              icon: "add",
              tooltip: "Adicionar  Empresa",
              onClick: () => this.setState({ newEnterprise: true }),
              isFreeAction: true,
            },
          ]}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataUpdate = [...this.state.data];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  this.setState({ data: dataUpdate });

                  resolve();
                }, 1000);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataDelete = [...this.state.data];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  this.setState({ data: dataDelete });

                  resolve();
                }, 1000);
              }),
          }}
        />
      </Paper>
    );
  }
}

export default Usuario;
