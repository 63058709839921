import { Typography, withStyles } from "@material-ui/core";

const BaseDialogContentText = withStyles((theme) => ({
  root: {
    fontWeight: "normal",
    fontSize: "14px",

    marginBottom: "16px",
  },
}))(Typography);

export default BaseDialogContentText;
