import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import SettingsIcon from "@material-ui/icons/Settings";
import { useMediaQuery } from "@material-ui/core";
import { useTranslate, MenuItemLink, getResources } from "react-admin";
import Pedido from "@material-ui/icons/Assignment";
import AddBoxIcon from "@material-ui/icons/AddBox";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SubMenu from "./SubMenu";
import DomainIcon from "@material-ui/icons/Domain";
import FolderIcon from '@material-ui/icons/Folder';
import AssessmentIcon from '@material-ui/icons/Assessment';
import {
  AspectRatio,
  Settings,
  StarHalf,
  Subtitles,
  Assessment,
  AddBox,
} from "@material-ui/icons";

const Menu = (props) => {
  const resources = useSelector(getResources);
  const [state, setState] = useState({
    menuCatalog: false,
    menuCadastro: false,
    menuConfigs: false,
    menuRevenues: false,
    menuCustomers: false,
  });
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  useSelector((state) => state.theme); // force rerender on theme change

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <div >
      {/* <DashboardMenuItem onClick={props.onMenuClick} sidebarIsOpen={open} /> */}
      {resources.map((resource) => {
        if (resource.name.includes("pop")) {
          return (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={`${resource.options.label}`}
              leftIcon={<Pedido />}
              onClick={props.onMenuClick}
              sidebarIsOpen={false}
              style={{
                whiteSpace: 'nowrap',
                overFlow: "hidden",
                textOverflow: "ellipsis",
                width: "240px",
                display: "inline-block",
                verticalAlign: "middle",
              }}
            />
          );
        } else if (resource.name === "Usuario") {
          return (
            <SubMenu
              handleToggle={() => handleToggle("menuCadastro")}
              isOpen={state.menuCadastro}
              sidebarIsOpen={open}
              name="Cadastros"
              icon={<AddBoxIcon />}
            >
              <MenuItemLink
                to={`/usuario`}
                primaryText={`Usuários`}
                leftIcon={<PersonAddIcon />}
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
              />
              <MenuItemLink
                to={`/empresa`}
                primaryText={`Empresas`}
                leftIcon={<DomainIcon />}
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
              />
              {/* <MenuItemLink
                to={`/folder`}
                primaryText={`Pastas`}
                leftIcon={<FolderIcon />}
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
              /> */}
              <MenuItemLink
                to={`/qrcode`}
                primaryText={`QR Code`}
                leftIcon={<FolderIcon />}
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
              />
            </SubMenu>
          );
        } else if (resource.name === "configStatusStudy") {
          return (
            <SubMenu
              handleToggle={() => handleToggle("menuConfigs")}
              isOpen={state.menuConfigs}
              sidebarIsOpen={open}
              name="Configurações"
              icon={<Settings />}
            >
              <MenuItemLink
                to={`/configStatusStudy`}
                primaryText={`Status de Exame`}
                leftIcon={<Subtitles />}
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
              />
              <MenuItemLink
                to={`/configPriority`}
                primaryText={`Prioridades`}
                leftIcon={<StarHalf />}
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
              />
              <MenuItemLink
                to={`/configPortal`}
                primaryText={`Portal de Resultados`}
                leftIcon={<AspectRatio />}
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
              />
              <MenuItemLink
                to={`/configHTML`}
                primaryText={`HTML Laudo`}
                leftIcon={<AddBox />}
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
              />
            </SubMenu>
          );
        } else if (resource.name === "procedure") {
          return (
            <SubMenu
              handleToggle={() => handleToggle("menuRevenues")}
              isOpen={state.menuRevenues}
              sidebarIsOpen={open}
              name="Faturamento"
              icon={<Assessment />}
            >
              <MenuItemLink
                to={`/procedure`}
                primaryText={`Procedimento`}
                leftIcon={<Subtitles />}
                onClick={props.onMenuClick}
                sidebarIsOpen={open}
              />
              {/* <MenuItemLink
                            to={`/groupProcedure`}
                            primaryText={`Grupo Procedimento`}
                            leftIcon={<Subtitles />}
                            onClick={props.onMenuClick}
                            sidebarIsOpen={open}
                        />
                        <MenuItemLink
                            to={`/tableValues`}
                            primaryText={`Tabela de Valores`}
                            leftIcon={<Subtitles />}
                            onClick={props.onMenuClick}
                            sidebarIsOpen={open}
                        />
                        <MenuItemLink
                            to={`/rules`}
                            primaryText={`Regras`}
                            leftIcon={<Subtitles />}
                            onClick={props.onMenuClick}
                            sidebarIsOpen={open}
                        />
                        <MenuItemLink
                            to={`/groupProvider`}
                            primaryText={`Grupo Prestador`}
                            leftIcon={<Subtitles />}
                            onClick={props.onMenuClick}
                            sidebarIsOpen={open}
                        />
                        <MenuItemLink
                            to={`/createdRevenues`}
                            primaryText={`Gerar Faturamento`}
                            leftIcon={<Subtitles />}
                            onClick={props.onMenuClick}
                            sidebarIsOpen={open}
                        /> */}
            </SubMenu>
          );
        } else if (resource.name === "audit") {
          return (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={`${resource.options.label}`}
              leftIcon={<FolderIcon />}
              onClick={props.onMenuClick}
              sidebarIsOpen={open}
            />
          );
        } else if (resource.name === "myaudit") {
          return (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={`${resource.options.label}`}
              leftIcon={<FolderIcon />}
              onClick={props.onMenuClick}
              sidebarIsOpen={open}
            />
          );
        } else if (resource.name === "report") {
          return (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={`${resource.options.label}`}
              leftIcon={<AssessmentIcon />}
              onClick={props.onMenuClick}
              sidebarIsOpen={open}
            />
          );
        }
      })}
      {isXSmall && (
        <MenuItemLink
          to="/configuration"
          primaryText={translate("configuration")}
          leftIcon={<SettingsIcon />}
          onClick={props.onMenuClick}
          sidebarIsOpen={open}
        />
      )}
      {isXSmall && props.logout}
    </div>
  );
};

export default Menu;
