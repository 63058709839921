import React from "react";
import {
  TextField,
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import api from "../../../service/api";
import DialogError from "../../alertas/DialogError";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
const BPF_SERVER_URL = process.env.REACT_APP_BPF_SERVER_URL;

class InsertUsuario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      nome: "",
      localidade: "",
      showError: false,
      imgSrc: "",
      scale: "1",
      crop: null,
      aspect: undefined,
      type: 0,
      userTypes: [],
    };
    this.imgRef = new React.createRef();
    this.previewCanvasRef = new React.createRef();
    this.hiddenAnchorRef = new React.createRef();
    this.blobUrlRef = new React.createRef();
  }
  componentDidMount() {
    this.getUserTypes();
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  handleTypeUserChange = (event) => {
    this.setState({ type: event.target.value });
  };

  getUserTypes = async () => {
    const types = await api
      .get("/user-types")
      .then((res) => res.data.data.result.data);
    this.setState({ userTypes: types });
  };

  save = async () => {
    try {
      if (this.state.file == undefined) {
        var error_message = {
          title: "Erro ao inserir usuário",
          subTitle: "Ao salvar, usuário não contém imagem",
        };
        this.setState({
          showError: true,
          error: error_message,
        });
        return;
      }
      const url = window.location.hostname;
      var url_split = url.split(".");
      var blob_cort = await this.ajustSignature();
      let file_cort = new File([blob_cort], this.state.file?.name);
      const formData = new FormData();
      formData.append("file", file_cort);

      console.log(file_cort);

      api
        .post("/storeSignatureUser", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((respSignature) => {
          api
            .post(`${BPF_SERVER_URL}/api/user/register`, {
              name: this.state.nome,
              email: this.state.email,
              password: this.state.password,
              c_password: this.state.c_password,
              signatureUrl: respSignature.data.signature,
            })
            .then((resp) => {
              var id_user = resp.data.data.result.id;
              if (id_user != null) {
                api.post("/userHasEnterprises", {
                  user_id: id_user,
                  domain: url_split[0],
                });

                api.post("/user-type/vinculate-user-type", {
                  user_id: id_user,
                  usertype_id: this.state.type,
                });
                this.props.save();
              }
            });
        });
    } catch (error) {
      console.log(error);
      var error_message = {
        title: "Erro ao inserir usuário",
        subTitle: "Ao salvar, vimos que não contém imagem",
      };
      this.setState({
        showError: true,
        error: error_message,
      });
    }
  };
  handleFileSelect = (event) => {
    console.log(event.target.files[0]);
    this.setState({ file: event.target.files[0] });
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      this.setState({ imgSrc: reader.result?.toString() || "" })
    );
    reader.readAsDataURL(event.target.files[0]);
  };
  onImageLoad = (e) => {
    if (this.state.aspect) {
      const { width, height } = e.currentTarget;
      this.setState({
        crop: centerAspectCrop(width, height, this.state.aspect),
      });
    }
  };
  handleComplete = (c) => {
    this.setState({ completedCrop: c });
    if (
      c?.width &&
      c?.height &&
      this.imgRef.current &&
      this.previewCanvasRef.current
    ) {
      // We use canvasPreview as it's much faster than imgPreview.
      canvasPreview(
        this.imgRef.current,
        this.previewCanvasRef.current,
        c,
        this.state.scale,
        this.state.rotate
      );
    }
  };
  onDownloadCropClick = async () => {
    const image = this.imgRef.current;
    const previewCanvas = this.previewCanvasRef.current;
    if (!image || !previewCanvas || !this.state.completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      this.state.completedCrop.width * scaleX,
      this.state.completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: "image/png",
    });

    if (this.blobUrlRef.current) {
      URL.revokeObjectURL(this.blobUrlRef.current);
    }
    this.blobUrlRef.current = URL.createObjectURL(blob);
    console.log(this.blobUrlRef);
    console.log(URL.createObjectURL(blob));
    console.log(blob);
    if (this.hiddenAnchorRef.current) {
      this.hiddenAnchorRef.current.href = this.blobUrlRef.current;
      this.hiddenAnchorRef.current.click();
    }
  };
  ajustSignature = async () => {
    const image = this.imgRef.current;
    const previewCanvas = this.previewCanvasRef.current;
    if (!image || !previewCanvas || !this.state.completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      this.state.completedCrop.width * scaleX,
      this.state.completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext("2d");
    if (!ctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: "image/png",
    });

    if (this.blobUrlRef.current) {
      URL.revokeObjectURL(this.blobUrlRef.current);
    }
    return blob;
  };
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.open}
        onClose={() => this.props.close()}
        aria-labelledby="form-dialog-title"
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogError
          open={this.state.showError}
          onClose={() => this.setState({ showError: false })}
          title={
            this.state.error ? this.state.error.title : "Erro ao salvar laudo"
          }
          message={
            this.state.error
              ? this.state.error.subTitle
              : "Ao salvar, vimos que não contém conteúdo no editor do laudo."
          }
          suggestion={
            "Não sendo possível salvar o laudo, para resolver adicione o conteúdo do laudo ou exclua está entrada."
          }
        />
        <DialogTitle id="form-dialog-title">Inserir Usuario</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              className={classes.text}
              autoFocus
              margin="dense"
              id="name"
              label="Nome"
              value={this.state.nome}
              onChange={this.handleChange("nome")}
            />
            <TextField
              className={classes.text}
              type="email"
              margin="dense"
              id="email"
              label="email"
              value={this.state.email}
              onChange={this.handleChange("email")}
            />
            <TextField
              className={classes.text}
              margin="dense"
              type="password"
              id="password"
              label="Senha"
              value={this.state.password}
              onChange={this.handleChange("password")}
            />
            <TextField
              className={classes.text}
              margin="dense"
              type="password"
              id="password"
              label="Confirmar senha"
              value={this.state.c_password}
              onChange={this.handleChange("c_password")}
            />
            <TextField
              className={classes.text}
              margin="dense"
              type="file"
              id="signature"
              label="Assinatura"
              onChange={this.handleFileSelect}
            />
            <div>
              {!!this.state.imgSrc && (
                <ReactCrop
                  crop={this.state.crop}
                  onChange={(_, percentCrop) =>
                    this.setState({ crop: percentCrop })
                  }
                  onComplete={(c) => this.handleComplete(c)}
                  aspect={this.state.aspect}
                  // minWidth={400}
                  minHeight={100}
                  // circularCrop
                >
                  <img
                    ref={this.imgRef}
                    alt="Crop me"
                    src={this.state.imgSrc}
                    style={{
                      transform: `scale(${this.state.scale}) rotate(${this.state.rotate}deg)`,
                    }}
                    onLoad={this.onImageLoad}
                  />
                </ReactCrop>
              )}

              {!!this.state.completedCrop && (
                <>
                  <div>
                    <canvas
                      ref={this.previewCanvasRef}
                      style={{
                        border: "1px solid black",
                        objectFit: "contain",
                        width: this.state.completedCrop.width,
                        height: this.state.completedCrop.height,
                      }}
                    />
                  </div>
                  {/* <div>
                                        <a
                                            href="#hidden"
                                            ref={this.hiddenAnchorRef}
                                            download
                                            style={{
                                                position: 'absolute',
                                                top: '-200vh',
                                                visibility: 'hidden',
                                            }}
                                        >
                                            Hidden download
                                        </a>
                                    </div> */}
                </>
              )}
            </div>

            <InputLabel className={classes.text}>Tipo de usuário</InputLabel>
            <Select
              className={classes.select}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.type}
              label="Tipo de usuário"
              onChange={(e) => this.handleTypeUserChange(e)}
            >
              {this.state.userTypes.map((userType) => {
                return (
                  <MenuItem key={userType.id} value={userType.id}>
                    {userType.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.close()} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => this.save()} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = (theme) => ({
  formControl: {
    width: 200,
    margin: "13px",
  },
  select: {
    margin: theme.spacing(2),
    width: 150,
  },
  text: {
    margin: theme.spacing(2),
  },
  textId: {
    margin: theme.spacing(2),
    width: 40,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
    display: "flex",
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -4,
    left: -8,
    zIndex: 1,
  },
  searchButton: {
    marginTop: "20px",
  },
});
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}
const TO_RADIANS = Math.PI / 180;

function canvasPreview(image, canvas, crop, scale = 1, rotate = 0) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

export default withStyles(styles)(InsertUsuario);
