import React from "react";
import {
  TextField,
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import api from "../../../service/api";

class InsertUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      nome: "",
      email: "",
    };
  }
  componentDidMount() {}

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  save = () => {
    const url = window.location.hostname;
    var url_split = url.split(".");
    api.get(`/getDomain?domain=${url_split[0]}`).then((resp_enterprise) => {
      var enterprise_id = resp_enterprise.data.data.result.id;
      api
        .post("/user-share", {
          name: this.state.nome,
          email: this.state.email,
          enterprise: enterprise_id,
        })
        .then((resp) => {
          this.props.save();
        });
    });
  };
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.open}
        onClose={() => this.props.close()}
        aria-labelledby="form-dialog-title"
        maxWidth={"sm"}
        fullWidth={true}
        style={{ zIndex: "12000" }}
      >
        <DialogTitle id="form-dialog-title">Inserir Usuário</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              className={classes.text}
              autoFocus
              margin="dense"
              id="name"
              label="Nome"
              value={this.state.nome}
              onChange={this.handleChange("nome")}
            />
            <TextField
              className={classes.text}
              autoFocus
              margin="dense"
              id="email"
              label="Email"
              value={this.state.email}
              onChange={this.handleChange("email")}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.close()} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => this.save()} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = (theme) => ({
  dialogContainer: {
    zIndex: 12000,
  },
  formControl: {
    width: 200,
    margin: "13px",
  },
  text: {
    margin: theme.spacing(2),
  },
  textId: {
    margin: theme.spacing(2),
    width: 40,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
    display: "flex",
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -4,
    left: -8,
    zIndex: 1,
  },
  searchButton: {
    marginTop: "20px",
  },
});

export default withStyles(styles)(InsertUser);
