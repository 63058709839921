import React from "react";
import {
  FormControl,
  InputLabel,
  Checkbox,
  TextareaAutosize,
  TextField,
  withStyles,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import Dropzone from "react-dropzone-uploader";
import "../styles/dropzone.css";
import DeleteIcon from "@material-ui/icons/Delete";
import ModalImage from "react-modal-image";
import SignatureCanvas from "react-signature-canvas";
import Autocomplete from "@material-ui/lab/Autocomplete";

const BPF_SERVER_URL = process.env.REACT_APP_BPF_SERVER_URL;
class CustomForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderField = (field, index) => {
    const {
      type,
      component,
      options,
      columns,
      body,
      value,
      multi,
      source,
      centered,
      scale,
      index_signature,
    } = field;
    const { classes } = this.props;
    switch (type) {
      case "input":
        return (
          <TextField
            id="standard-basic"
            label={component.label}
            value={value == null ? "" : value}
            variant="outlined"
            className={classes.textField}
            margin="normal"
            onChange={(e) => this.props.handleChangeTextForm(e, index)}
          />
        );
      case "input-full":
        return (
          <TextField
            fullWidth
            id="standard-basic"
            label={component.label}
            value={value == null ? "" : value}
            variant="outlined"
            margin="normal"
            onChange={(e) => this.props.handleChangeTextForm(e, index)}
          />
        );
      case "date":
        return (
          <TextField
            id="date"
            label={component.label}
            value={value == null ? "" : value}
            variant="outlined"
            type="date"
            defaultValue="2017-05-24"
            className={classes.textFieldDate}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => this.props.handleChangeTextForm(e, index)}
          />
        );
      case "checkbox":
        return (
          <div key={component.label} className={classes.divCheckbox}>
            {component.label != "" && (
              <h4 style={{ width: "50%" }}>{component.label}</h4>
            )}
            <div>
              {options.map((checkbox, index_component) => {
                if (checkbox.type == "checkbox") {
                  return (
                    <label className={classes.labelCheckbox}>
                      <Checkbox
                        checked={checkbox.checked}
                        onChange={(e) =>
                          multi == true
                            ? this.props.handleChangeCheckboxFormMulti(
                              e,
                              index,
                              index_component
                            )
                            : this.props.handleChangeCheckboxForm(
                              e,
                              index,
                              index_component
                            )
                        }
                        name="gilad"
                      />
                      {checkbox.label == null ? "" : checkbox.label}
                    </label>
                  );
                } else if (checkbox.type == "obs") {
                  return (
                    <TextField
                      id="standard-basic"
                      label={checkbox.label}
                      value={checkbox.value == null ? "" : checkbox.value}
                      className={classes.textFieldObs}
                      margin="normal"
                      onChange={(e) =>
                        this.props.handleChangeCheckboxObs(
                          e,
                          index,
                          index_component
                        )
                      }
                    />
                  );
                }
              })}
            </div>
          </div>
        );
      case "textarea":
        return (
          <TextareaAutosize
            rows={5}
            placeholder={component.label}
            className={classes.textArea}
            value={value == null ? "" : value}
            onChange={(e) => this.props.handleChangeTextForm(e, index)}
          />
        );
      case "text-info":
        return (
          <Paper elevation={3} className={classes.containerPaperInfo}>
            <TextareaAutosize
              readOnly
              rows={5}
              placeholder={component.label}
              className={classes.textInfo}
              value={value == null ? "" : value}
            />
          </Paper>
        );
      case "select":
        return (
          <FormControl className={classes.formControl}>
            <InputLabel id="select-sbr-bpf-simple" >
              {component.label}
            </InputLabel>
            <Select
              labelId="select-sbr-bpf-simple"
              id="sbr-simple-select"
              value={value == null ? "" : value}
              onChange={(e) => this.props.handleSelectOptions(e, index)}

            >
              <MenuItem value={""}></MenuItem>
              {options.map((item, index_component) => {
                return <MenuItem value={item.label}>{item.label}</MenuItem>;
              })}
            </Select>
          </FormControl>
        );
      case "table":
        var columns_label = columns.map((column) => column.component.label);
        return (
          <div className={classes.containerTable}>
            <Typography variant="h6" className={classes.titleTable}>
              {component.label}
            </Typography>
            <div className={classes.container}>
              {columns.map((column, index_component) => {
                if (
                  column.type != "checkbox" &&
                  column.type != "select" &&
                  column.type != "date" &&
                  column.type != "signature" &&
                  column.type != "select-func"
                ) {
                  return (
                    <TextField
                      id="standard-basic"
                      label={column.component.label}
                      value={column.value == null ? "" : column.value}
                      variant="outlined"
                      className={classes.textField}
                      margin="normal"
                      onChange={(e) =>
                        this.props.handleChangeTextTableForm(
                          e,
                          index,
                          index_component
                        )
                      }
                    />
                  );
                } else if (column.type == "date") {
                  return (
                    <TextField
                      id="date"
                      label={column.component.label}
                      value={column.value == null ? "" : column.value}
                      variant="outlined"
                      type="date"
                      defaultValue="2017-05-24"
                      className={classes.textFieldDate}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) =>
                        this.props.handleChangeTextTableForm(
                          e,
                          index,
                          index_component
                        )
                      }
                    />
                  );
                } else if (column.type == "checkbox") {
                  {
                    return column.options.map(
                      (checkbox, index_component_checkbox) => {
                        if (index_component_checkbox == 0) {
                          return (
                            <React.Fragment key={column.component.label}>
                              <Typography
                                variant="subtitle1"
                                className={classes.titleTable}
                              >
                                {column.component.label}
                              </Typography>

                              {/* Render the first checkbox */}
                              <label className={classes.labelCheckbox}>
                                <Checkbox
                                  checked={checkbox.checked}
                                  onChange={(e) =>
                                    column.multi == true
                                      ? this.props.handleChangeCheckboxFormTableMulti(
                                        e,
                                        index,
                                        index_component,
                                        index_component_checkbox
                                      )
                                      : this.props.handleChangeCheckboxFormTable(
                                        e,
                                        index,
                                        index_component,
                                        index_component_checkbox
                                      )
                                  }
                                  name={checkbox.label}
                                />
                                {checkbox.label}
                              </label>
                            </React.Fragment>
                          );
                        }
                        if (checkbox.type == "checkbox") {
                          return (
                            <label className={classes.labelCheckbox}>
                              <Checkbox
                                checked={checkbox.checked}
                                onChange={(e) =>
                                  column.multi == true
                                    ? this.props.handleChangeCheckboxFormTableMulti(
                                      e,
                                      index,
                                      index_component,
                                      index_component_checkbox
                                    )
                                    : this.props.handleChangeCheckboxFormTable(
                                      e,
                                      index,
                                      index_component,
                                      index_component_checkbox
                                    )
                                }
                                name={checkbox.label}
                              />
                              {checkbox.label}
                            </label>
                          );
                        } else if (checkbox.type == "checkbox") {
                          return (
                            <label className={classes.labelCheckbox}>
                              <Checkbox
                                checked={checkbox.checked}
                                onChange={(e) =>
                                  column.multi == true
                                    ? this.props.handleChangeCheckboxFormTableMulti(
                                      e,
                                      index,
                                      index_component,
                                      index_component_checkbox
                                    )
                                    : this.props.handleChangeCheckboxFormTable(
                                      e,
                                      index,
                                      index_component,
                                      index_component_checkbox
                                    )
                                }
                                name={checkbox.label}
                              />
                              {checkbox.label}
                            </label>
                          );
                        } else if (checkbox.type == "obs") {
                          return (
                            <TextField
                              id="standard-basic"
                              label={checkbox.label}
                              value={
                                checkbox.value == null ? "" : checkbox.value
                              }
                              className={classes.textFieldObs}
                              margin="normal"
                              onChange={(e) =>
                                this.props.handleChangeCheckboxObsTable(
                                  e,
                                  index,
                                  index_component,
                                  index_component_checkbox
                                )
                              }
                            />
                          );
                        }
                      }
                    );
                  }
                } else if (column.type == "select") {
                  return (
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">
                        {column.component.label}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={column.value == null ? "" : column.value}
                        onChange={(e) =>
                          this.props.handleChangeSelectFormTable(
                            e,
                            index,
                            index_component
                          )
                        }
                      >
                        <MenuItem value={""}></MenuItem>
                        {column.options.map((item, index_component) => {
                          return (
                            <MenuItem value={item.label}>{item.label}</MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  );
                } else if (column.type == "signature") {
                  return (
                    <div>
                      {column.source == undefined && (
                        <div className={classes.containerSignature}>
                          <Typography
                            variant="h6"
                            className={classes.titleTable}
                          >
                            {column.component.label}
                          </Typography>
                          <SignatureCanvas
                            ref={
                              this.props.signatureRefTable[
                              column.index_signature
                              ]
                            }
                            canvasProps={{
                              width: 400,
                              height: 200,
                              className: classes.signature,
                            }}
                          />
                          <div>
                            <Button
                              variant="contained"
                              size="small"
                              color="secondary"
                              className={classes.buttonAction}
                              onClick={() =>
                                this.props.handleClearSignatureTable(
                                  column.index_signature
                                )
                              }
                            >
                              Limpar
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              color="primary"
                              className={classes.buttonAction}
                              onClick={() =>
                                this.props.handleSignatureTable(
                                  index,
                                  index_component,
                                  column.index_signature
                                )
                              }
                            >
                              Salvar Assinatura
                            </Button>
                          </div>
                        </div>
                      )}
                      {column.source != undefined && (
                        <div className={classes.containerSignature}>
                          <Typography
                            variant="h6"
                            className={classes.titleTable}
                          >
                            {component.label}
                          </Typography>
                          <ModalImage
                            small={`${BPF_SERVER_URL}/showSignature?filename=${column.source}`}
                            large={`${BPF_SERVER_URL}/showSignature?filename=${column.source}`}
                            alt={column.source}
                          />
                        </div>
                      )}
                    </div>
                  );
                } else if (column.type == "select-func") {
                  return (
                    <Autocomplete
                      id="combo-user"
                      options={this.props.users}
                      getOptionLabel={(option) => option.name}
                      style={{ width: 300, margin: 5 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          label={column.component.label}
                          placeholder={column.component.label}
                        />
                      )}
                      onChange={(event, newValue) => {
                        this.props.handleSelectFuncFormTable(
                          newValue?.name,
                          index,
                          index_component
                        );
                      }}
                      // onChange={(e) => this.props.handleChangeSelectFormTable(e, index, index_component)}

                      value={value == null ? "" : value}
                    />
                  );
                }
              })}
            </div>
            <div className={classes.containerAdd}>
              <Button
                variant="contained"
                size="small"
                color="primary"
                className={classes.button}
                onClick={() => this.props.handleAddDatasTableForm(index)}
              // endIcon={<AddIcon>opção</AddIcon>}
              >
                ADICIONAR
              </Button>
            </div>
            <Typography variant="h6" className={classes.titleTable}>
              Lançamentos
            </Typography>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columns_label.map((name, index) => (
                      <TableCell key={index}>{name}</TableCell>
                    ))}
                    <TableCell key="delete">Deletar</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {body.map((row, index_component) => (
                    <TableRow key={`table-row-${index_component}`}>
                      {row.map((value) => (
                        <TableCell component="th" scope="row">
                          {value == null ? "" : value}
                        </TableCell>
                      ))}
                      <TableCell component="th" scope="row">
                        <IconButton
                          aria-label="delete"
                          className={classes.margin}
                          onClick={() =>
                            this.props.deleteBodyTable(index, index_component)
                          }
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      case "file":
        return (
          <div>
            <p className={classes.subTitle}>
              Adicionar algum arquivo que estará constando no formulario
            </p>
            <p className={classes.subTitle}>
              Clique no campo para adicionar ou substituir o arquivo
            </p>
            <div style={{ width: "96%" }}>
              <div className={classes.TextFieldMargin}>
                <Dropzone
                  addClassNames={"dzu-dropzone"}
                  maxFiles={1}
                  inputContent="clique para anexar"
                  onChangeStatus={this.handleChangeStatus}
                  accept="image/*"
                  canCancel={false}
                  canRestart={false}
                  canRemove={true}
                  SubmitButtonComponent={null}
                  initialFiles={[this.state.file]}
                ></Dropzone>
              </div>
            </div>
          </div>
        );
      case "table-info":
        var columns_label = columns.map((column) => column.component.label);
        return (
          <div className={classes.containerTable}>
            <Typography variant="h6" className={classes.titleTable}>
              {component.label}
            </Typography>
            {this.props.add == undefined && (
              <div className={classes.container}>
                {columns.map((column, index_component) => {
                  if (
                    column.type != "checkbox" &&
                    column.type != "select" &&
                    column.type != "date"
                  ) {
                    return (
                      <TextField
                        id="standard-basic"
                        label={column.component.label}
                        value={column.value == null ? "" : column.value}
                        variant="outlined"
                        className={classes.textField}
                        margin="normal"
                        onChange={(e) =>
                          this.props.handleChangeTextTableForm(
                            e,
                            index,
                            index_component
                          )
                        }
                      />
                    );
                  } else if (column.type == "date") {
                    return (
                      <TextField
                        id="date"
                        label={column.component.label}
                        value={column.value == null ? "" : column.value}
                        variant="outlined"
                        type="date"
                        defaultValue="2017-05-24"
                        className={classes.textFieldDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) =>
                          this.props.handleChangeTextTableForm(
                            e,
                            index,
                            index_component
                          )
                        }
                      />
                    );
                  } else if (column.type == "checkbox") {
                    {
                      return column.options.map(
                        (checkbox, index_component_checkbox) => {
                          if (index_component_checkbox == 0) {
                            return (
                              <React.Fragment key={column.component.label}>
                                <Typography
                                  variant="subtitle1"
                                  className={classes.titleTable}
                                >
                                  {column.component.label}
                                </Typography>

                                {/* Render the first checkbox */}
                                <label className={classes.labelCheckbox}>
                                  <Checkbox
                                    checked={checkbox.checked}
                                    onChange={(e) =>
                                      column.multi == true
                                        ? this.props.handleChangeCheckboxFormTableMulti(
                                          e,
                                          index,
                                          index_component,
                                          index_component_checkbox
                                        )
                                        : this.props.handleChangeCheckboxFormTable(
                                          e,
                                          index,
                                          index_component,
                                          index_component_checkbox
                                        )
                                    }
                                    name={checkbox.label}
                                  />
                                  {checkbox.label}
                                </label>
                              </React.Fragment>
                            );
                          }
                          if (checkbox.type == "checkbox") {
                            return (
                              <label className={classes.labelCheckbox}>
                                <Checkbox
                                  checked={checkbox.checked}
                                  onChange={(e) =>
                                    column.multi == true
                                      ? this.props.handleChangeCheckboxFormTableMulti(
                                        e,
                                        index,
                                        index_component,
                                        index_component_checkbox
                                      )
                                      : this.props.handleChangeCheckboxFormTable(
                                        e,
                                        index,
                                        index_component,
                                        index_component_checkbox
                                      )
                                  }
                                  name={checkbox.label}
                                />
                                {checkbox.label}
                              </label>
                            );
                          } else if (checkbox.type == "checkbox") {
                            return (
                              <label className={classes.labelCheckbox}>
                                <Checkbox
                                  checked={checkbox.checked}
                                  onChange={(e) =>
                                    column.multi == true
                                      ? this.props.handleChangeCheckboxFormTableMulti(
                                        e,
                                        index,
                                        index_component,
                                        index_component_checkbox
                                      )
                                      : this.props.handleChangeCheckboxFormTable(
                                        e,
                                        index,
                                        index_component,
                                        index_component_checkbox
                                      )
                                  }
                                  name={checkbox.label}
                                />
                                {checkbox.label}
                              </label>
                            );
                          } else if (checkbox.type == "obs") {
                            return (
                              <TextField
                                id="standard-basic"
                                label={checkbox.label}
                                value={
                                  checkbox.value == null ? "" : checkbox.value
                                }
                                className={classes.textFieldObs}
                                margin="normal"
                                onChange={(e) =>
                                  this.props.handleChangeCheckboxObsTable(
                                    e,
                                    index,
                                    index_component,
                                    index_component_checkbox
                                  )
                                }
                              />
                            );
                          }
                        }
                      );
                    }
                  } else if (column.type == "select") {
                    return (
                      <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                          {column.component.label}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={column.value == null ? "" : column.value}
                          onChange={(e) =>
                            this.props.handleChangeSelectFormTable(
                              e,
                              index,
                              index_component
                            )
                          }
                        >
                          <MenuItem value={""}></MenuItem>
                          {column.options.map((item, index_component) => {
                            return (
                              <MenuItem value={item.label}>
                                {item.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    );
                  }
                })}
              </div>
            )}
            {this.props.add == undefined && (
              <div className={classes.containerAdd}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  className={classes.button}
                  onClick={() => this.props.handleAddDatasTableForm(index)}
                // endIcon={<AddIcon>opção</AddIcon>}
                >
                  ADICIONAR
                </Button>
              </div>
            )}
            {/* <Typography variant="h6" className={classes.titleTable}>Lançamentos</Typography> */}
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columns_label.map((name, index) => (
                      <TableCell key={index}>{name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {body.map((row, index_component) => (
                    <TableRow key={`table-row-${index_component}`}>
                      {row.map((value) => (
                        <TableCell component="th" scope="row">
                          {value == null ? "" : value}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      case "image":
        return (
          <div
            style={{
              width: "100%",
              display: centered ? "flex" : null,
              alignItems: centered ? "center" : null,
              justifyContent: centered ? "center" : null,
            }}
          >
            <div
              style={{
                maxWidth: `${scale}%`,
              }}
            >
              <ModalImage
                small={`${BPF_SERVER_URL}/showImage?filename=${source}`}
                large={`${BPF_SERVER_URL}/showImage?filename=${source}`}
                alt={source}
              />
            </div>
          </div>
        );
        break;
      case "signature":
        return (
          <div>
            {source == undefined && (
              <div className={classes.containerSignature}>
                <Typography variant="h6" className={classes.titleTable}>
                  {component.label}
                </Typography>
                <SignatureCanvas
                  ref={this.props.signatureRef[index_signature]}
                  canvasProps={{
                    width: 400,
                    height: 200,
                    className: classes.signature,
                  }}
                />
                <div>
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    className={classes.buttonAction}
                    onClick={() =>
                      this.props.handleClearSignature(index_signature)
                    }
                  // endIcon={<AddIcon>opção</AddIcon>}
                  >
                    Limpar
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    className={classes.buttonAction}
                    onClick={() =>
                      this.props.handleSignature(index, index_signature)
                    }
                  // endIcon={<AddIcon>opção</AddIcon>}
                  >
                    Salvar Assinatura
                  </Button>
                </div>
              </div>
            )}
            {source != undefined && (
              <div className={classes.containerSignature}>
                <Typography variant="h6" className={classes.titleTable}>
                  {component.label}
                </Typography>
                <ModalImage
                  small={`${BPF_SERVER_URL}/showSignature?filename=${source}`}
                  large={`${BPF_SERVER_URL}/showSignature?filename=${source}`}
                  alt={source}
                />
              </div>
            )}
          </div>
        );
        break;
      case "select-func":
        return (
          <Autocomplete
            id="combo-user"
            options={this.props.users}
            getOptionLabel={(option) => option.name}
            style={{ width: 300, margin: 5 }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label={component.label}
                placeholder={component.label}
              />
            )}
            onChange={(event, newValue) => {
              this.props.handleSelectFunc(index, newValue);
            }}
            value={value == null ? "" : value}
          />
        );
        break;
      case "title":
        return (
          <div className={classes.divTitle}>
            <Typography variant="h5" gutterBottom>
              {component.label}
            </Typography>
          </div>
        );
      case "title-black":
        return (
          <div className={classes.divTitle}>
            <Typography variant="h5" gutterBottom>
              {component.label}
            </Typography>
          </div>
        );
      case "video":
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <h2>Vídeo</h2> */}
            <video controls width="600">
              <source src={source} type="video/mp4" />
              Seu navegador não suporta o elemento de vídeo.
            </video>
          </div>
        );
        break;
      case "audio":
        return (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <h2>Vídeo</h2> */}
            <audio controls>
              <source src={source} type="audio/mpeg" />
              Seu navegador não suporta o elemento de áudio.
            </audio>
          </div>
        );
        break;
      case "select-auto":
        return (
          <FormControl className={classes.formControlFlex}>
            <InputLabel id="demo-simple-select-label" className={classes.inputLabel}>
              {component.label}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value == null ? "" : value}
              onChange={(e) => this.props.handleSelectOptions(e, index)}
            >
              <MenuItem value={""}></MenuItem>
              {options.map((item, index_component) => {
                return <MenuItem value={item.label}>{item.label}</MenuItem>;
              })}
            </Select>
          </FormControl>
        );
      default:
        return null;
    }
  };
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        {this.props.id_data_form != null && (
          <div>
            <TextField
              id="standard-basic"
              disabled
              label={"Código"}
              value={this.props.id_data_form}
              variant="outlined"
              className={classes.textId}
              margin="normal"
            />
          </div>
        )}
        {this.props.fields.map((field, index) =>
          this.renderField(field, index)
        )}
      </div>
    );
  }
}
const styles = (theme) => ({
  centeredImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textId: {
    width: 100,
    margin: 5,
  },
  textField: {
    width: 300,
    margin: 5,
  },
  textFieldObs: {
    width: 250,
    margin: "0px 15px 0px 15px",
  },
  textFieldDate: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  textArea: {
    width: "100%",
  },
  containerAdd: {
    justifyContent: "end",
    display: "flex",
    marginBottom: 15,
  },
  titleTable: {
    fontWeight: "bold",
  },
  containerTable: {
    marginTop: 20,
    marginBottom: 20,
  },
  labelCheckbox: {
    alignSelf: "center",
  },
  divCheckbox: {
    display: "flex",
    justifyContent: "space-between",
  },
  subTitle: {
    color: "#0078F0",
    fontSize: "12px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
  },
  formControlFlex: {
    margin: theme.spacing(1),
    width: "fit-content",
    maxWidth: "100%"
  },
  inputLabel: {
    display: "contents"
  },
  containerPaperInfo: {
    margin: theme.spacing(1),
    padding: 20,
  },
  textInfo: {
    width: "100%",
    border: "none",
    overflow: "auto",
    outline: "none",

    webkitBoxShadow: "none",
    mozBoxShadow: "none",
    boxShadow: "none",

    resize: "none" /*remove the resize handle on the bottom right*/,
  },
  signature: {
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  containerSignature: {
    textAlign: "center",
  },
  buttonAction: {
    margin: 5,
  },
  divTitle: {
    textAlign: "center",
    margin: 5,
  },
});
export default withStyles(styles)(CustomForm);
