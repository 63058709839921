import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import BaseDialog from "./BaseDialog";
import BaseDialogContentText from "./BaseContentText";

export default function DialogError({
  open,
  title,
  message,
  suggestion,
  onClose,
}) {
  return (
    <BaseDialog
      icon={<ErrorIcon color="error" />}
      title={title}
      labelledby={"dialog-error"}
      maxWidth="xs"
      open={open}
      onClose={onClose}
      actions={[
        <Button color="secondary" onClick={onClose} key={1}>
          OK
        </Button>,
      ]}
    >
      <BaseDialogContentText>{message}</BaseDialogContentText>
    </BaseDialog>
  );
}
DialogError.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  suggestion: PropTypes.string.isRequired,
};
