import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
} from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/core";
import api from "../../../service/api";
import MaterialTable from "material-table";

const BPF_SERVER_URL = process.env.REACT_APP_BPF_SERVER_URL;

class Logs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data_form: props.data_form,
            data: [],
            loading: false
        };
    }


    handleClose = async () => {
        await this.props.handleClose();
    };

    componentDidMount = async () => {
        this.getLogs(this.state.data_form)
    };

    getLogs = (data_form) => {
        this.setState({ loading: true })
        api.get(`/form-logs/${data_form}`).then(resp => {
            this.setState({ data: resp.data, loading: false })
        })
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog
                    fullWidth={true}
                    maxWidth={"lg"}
                    open={true}
                    onClose={() => this.handleClose()}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle
                        className={classes.DialogTitle}
                        id="max-width-dialog-title"
                    >
                        Histórico de Versões do Formulário
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText>
                            Nesta tela, você pode visualizar o histórico completo das versões deste formulário.
                            Cada versão reflete uma modificação realizada ao longo do tempo, permitindo o acompanhamento das alterações feitas por diferentes usuários.
                        </DialogContentText>
                        <div style={{ maxWidth: '98%' }}>
                            <MaterialTable
                                isLoading={this.state.loading}
                                columns={[
                                    {
                                        title: 'Data', field: 'created_at', render: rowData => {
                                            const utcDate = new Date(rowData.created_at);
                                            const localDate = utcDate.toLocaleString(); // Converte para o horário local
                                            return localDate;
                                        }
                                    },
                                    { title: 'Versão', field: 'version' },
                                    { title: 'Id', field: 'id', hidden: true },
                                    { title: 'Modificado por', field: 'changed_by.name' },
                                    // { title: 'Comentario', field: 'comment' },
                                ]}
                                actions={[
                                    {
                                        icon: "description",
                                        tooltip: "PDF",
                                        onClick: (event, rowData) => {
                                            window.open(
                                                `${BPF_SERVER_URL}/old-version-form/${rowData.id}`
                                            );
                                        }
                                    }
                                ]}
                                data={this.state.data}
                                title="Versões"
                            />
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => this.handleClose()}
                            className={classes.button}
                        >
                            Voltar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
const useStyles = (theme) => ({
    dialogContent: {
        padding: "8px 0px 0px 24px",
        "& .MuiInputBase-root": {
            fontSize: "12px",
        },
    },
    DialogTitle: {
        "& .MuiTypography-h6": {
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "21.09px",
            color: "#0D3C61",
        },
    },
    button: {
        fontSize: "10px",
        color: "#0078F0",
        marginRight: theme.spacing(1),
    },
    formControl: {
        width: "200px",
        margin: "10px",
    },

});

export default withStyles(useStyles)(Logs);
