import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import api from "../../service/api";

function MyAudit() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAudits()
    }, []);

    const getAudits = () => {
        setLoading(true)
        api.get(`my-change-requests`).then(resp => {
            setLoading(false)
            setData(resp.data)
        })
    }

    return (<Paper>
        <div style={{ maxWidth: '100%' }}>
            <MaterialTable
                isLoading={loading}
                columns={[
                    {
                        title: 'Data', field: 'created_at', render: rowData => {
                            const utcDate = new Date(rowData.created_at);
                            const localDate = utcDate.toLocaleString(); // Converte para o horário local
                            return localDate;
                        }
                    },
                    { title: 'Form', field: 'form_name' },
                    { title: 'ID Form', field: 'data_form_id' },
                    { title: 'Comentario', field: 'comment' },
                    { title: 'ID Solicitação', field: 'id', hidden: true },
                    { title: 'Status', field: 'status', lookup: { "pending": 'Pendente', "approved": 'Aprovado', "rejected": 'Recusado', "finished": 'Finalizado' } },
                    { title: 'Solicitado por', field: 'requested_by.name' },
                    { title: 'Requisitado', field: 'requested_for.name' },
                    { title: 'Respondido por', field: 'supervisor.name' },
                    {
                        title: 'Data resposta', field: 'approved_at', render: rowData => {
                            if (rowData.approved_at != null) {
                                const utcDate = new Date(rowData.approved_at);
                                const localDate = utcDate.toLocaleString(); // Converte para o horário local
                                return localDate;
                            }

                        }
                    },
                ]}
                data={data}
                title="Solicitações de auditoria"
            />
        </div>
    </Paper>)
}

export default MyAudit