import React from 'react';
import { AppBar, UserMenu, MenuItemLink, translate } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { Settings, Lock } from '@material-ui/icons';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import Password from './Password';
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
};

const CustomAppBar = ({ classes, ...props }) => {
    const [passwordIsOpen, setPasswordIsOpen] = React.useState(false);

    const CustomUserMenu = translate(({ translate, ...props }) => (
        <UserMenu {...props}>
            <ConfigurationMenu />
        </UserMenu>
    ));
    const ConfigurationMenu = () => (
        <MenuItem onClick={() => setPasswordIsOpen(true)}>
            <ListItemIcon style={{ minWidth: '40px' }}><Lock /></ListItemIcon>
            <Typography style={{ color: '#0000008a' }}>Alterar senha</Typography>
        </MenuItem>
    );
    return (
        <AppBar {...props} userMenu={<CustomUserMenu />}>
            <Typography
                variant="title"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />
            <span>{CryptoJS.AES.decrypt(Cookies.get('name_bpf'), SECRET_KEY).toString(CryptoJS.enc.Utf8)}</span>
            {passwordIsOpen &&
                <Password open={passwordIsOpen} close={() => setPasswordIsOpen(false)} />}
        </AppBar>
    )
};

export default withStyles(styles)(CustomAppBar);
