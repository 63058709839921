import React from "react";
import {
    TextField,
    Button,
    withStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import DialogError from "../alertas/DialogError";
import api from "../../service/api";
import Cookies from 'js-cookie';

class AlterPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            nome: "",
            localidade: "",
            showError: false,
        };
    }

    handleChange = (name) => (event) => {
        this.setState({ [name]: event.target.value });
    };

    save = async () => {
        if (this.state.password != this.state.c_password) {
            var error_message = {
                title: "Erro ao alterar senha do usuário",
                subTitle: "Ao salvar, vimos que as senhas não são iguais",
            };
            this.setState({
                showError: true,
                error: error_message,
            });
        }
        try {
            api
                .post("/user/password", {
                    password: this.state.password,
                    c_password: this.state.c_password,
                })
                .then(() => {
                    Cookies.remove('name_bpf');
                    Cookies.remove('permissions_bpf');
                    Cookies.remove('access_token');

                    window.location.assign('#/Login')
                });
        } catch (error) {
            var error_message = {
                title: "Erro ao alterar senha do usuário",
                subTitle: "Ao salvar, vimos que as senhas não são iguais",
            };
            this.setState({
                showError: true,
                error: error_message,
            });
        }
    };

    render() {
        const { classes } = this.props;
        return (
            <Dialog
                open={this.state.open}
                onClose={() => this.props.close()}
                aria-labelledby="form-dialog-title"
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogError
                    open={this.state.showError}
                    onClose={() => this.setState({ showError: false })}
                    title={
                        this.state.error ? this.state.error.title : "Erro ao salvar senha"
                    }
                    message={
                        this.state.error
                            ? this.state.error.subTitle
                            : "Ao salvar, vimos que não contém conteúdo na senha."
                    }
                    suggestion={""}
                />
                <DialogTitle id="form-dialog-title">Alterar senha</DialogTitle>
                <DialogContent>
                    <div>
                        <TextField
                            className={classes.text}
                            margin="dense"
                            type="password"
                            id="password"
                            label="Senha"
                            value={this.state.password}
                            onChange={this.handleChange("password")}
                        />
                        <TextField
                            className={classes.text}
                            margin="dense"
                            type="password"
                            id="password"
                            label="Confirmar senha"
                            value={this.state.c_password}
                            onChange={this.handleChange("c_password")}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.close()} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={() => this.save()} color="primary">
                        Salvar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = (theme) => ({
    formControl: {
        width: 200,
        margin: "13px",
    },
    text: {
        margin: theme.spacing(2),
    },
    textId: {
        margin: theme.spacing(2),
        width: 40,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: "relative",
        display: "flex",
    },
    fabProgress: {
        color: green[500],
        position: "absolute",
        top: -4,
        left: -8,
        zIndex: 1,
    },
    searchButton: {
        marginTop: "20px",
    },
});

export default withStyles(styles)(AlterPassword);
