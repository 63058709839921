import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    TextField,
    makeStyles,
} from "@material-ui/core";
import api from "../../../service/api";

function RequestAudit({ onClose, onCloseSave, dataFormId }) {
    const [comment, setComment] = useState("");
    const classes = useStyles();

    const handleChange = (event) => {
        setComment(event.target.value);
    };

    const saveComment = () => {
        const url = window.location.hostname;
        let url_split = url.split(".");
        api.post(`change-requests/${dataFormId}`, {
            comment,
            domain: url_split[0]
        }).then(resp => {
            onCloseSave()
        })
    }
    return (<Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={true}
        onClose={() => onClose()}
        aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle
            className={classes.DialogTitle}
            id="max-width-dialog-title"
        >
            Descreva o motivo da edição
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <TextField
                id="outlined-multiline-static"
                label="Comentário"
                multiline
                defaultValue="Comentário"
                variant="outlined"
                onChange={handleChange}
                value={comment}
                className={classes.textComment}
            />
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => onClose()}
                className={classes.buttonCancel}
            >
                Cancelar
            </Button>
            <Button
                onClick={() => saveComment()}
                className={classes.buttonSave}
            >
                Salvar e Enviar
            </Button>
        </DialogActions>
    </Dialog>)
}

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        padding: "8px 0px 0px 24px",
        "& .MuiInputBase-root": {
            fontSize: "12px",
        },
    },
    DialogTitle: {
        "& .MuiTypography-h6": {
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "21.09px",
            color: "#0D3C61",
        },
    },
    textComment: {
        width: "98%",
    },
    buttonSave: {
        color: "white",
        background: "#2E8B57"
    },
    buttonCancel: {
        color: "white",
        background: "#D04A50"
    },
}));
export default RequestAudit