import { Paper, Tooltip, IconButton } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState, useRef } from "react";
import DialogQrCode from './components/DialogQrCode'
import api from "../../service/api";
import QrCode from "./components/QrCode";
import CloseIcon from '@material-ui/icons/Close';
import { getPermissions, validatePermission } from "../../libs/permissions";

const QRCODE_URL = process.env.REACT_APP_BPF_QRCODE_URL;

function QrCodeForms() {
    const [url, setUrl] = useState(null);
    const [data, setData] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const qrRef = useRef();

    useEffect(() => {
        validatePermission()
        getFormsQrCode()
    }, []);

    const columns = [
        { title: 'Formulário', field: 'form.name' },
        { title: 'Identificador', field: 'identifier' },
    ]

    const getFormsQrCode = () => {
        setLoading(true)
        const url = window.location.hostname;
        var url_splt = url.split(".");
        api.get("form_qrcodes", {
            params: {
                domain: url_splt[0]
            }
        }).then(resp => {
            setData(resp.data)
            setLoading(false)
        }).catch(error => {
            setLoading(false)
        })
    }
    const handleCloseSave = () => {
        setOpenDialog(false)
        getFormsQrCode();
    }
    const handleRefresh = (data) => {
        const url_host = window.location.hostname;
        var url_splt = url_host.split(".");
        let url = `${QRCODE_URL}/?form_id=${data.form_id}&domain=${url_splt[0]}&identifier=${data.identifier}`
        setUrl(url);
    }
    return (
        <Paper>
            {openDialog && <DialogQrCode handleClose={() => setOpenDialog(false)} handleCloseSave={handleCloseSave} />}
            <MaterialTable
                title="Qr Code"
                isLoading={loading}
                data={data}
                columns={columns}
                actions={[
                    {
                        icon: 'add',
                        tooltip: 'Adicionar QRCode',
                        isFreeAction: true,
                        onClick: (event, rowData) => setOpenDialog(true)
                    },
                    {
                        icon: 'refresh',
                        tooltip: 'Regerar QRCode',
                        onClick: (event, rowData) => handleRefresh(rowData)
                    },
                ]}
            />
            {url &&
                <div style={{ textAlign: "end", margin: 30 }}>
                    <Tooltip title="Fechar QR CODE">
                        <IconButton aria-label="delete" onClick={() => setUrl(null)}>
                            <CloseIcon small />
                        </IconButton>
                    </Tooltip>
                </div>

            }
            {url &&
                <div style={{ textAlign: "center", margin: 30 }} ref={qrRef}>
                    <QrCode url={url} />
                </div>
            }
        </Paper>
    );
}

export default QrCodeForms;