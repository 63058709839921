import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  dialogSize: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",

    gap: "8px",
  },
  dialogTextTitle: {
    fontSize: "18px",
    fontWeight: "normal",
  },
}));

export default function BaseDialog({
  labelledby,
  title,
  icon,
  open,
  buttonCloseText = "Fechar",
  onClose,
  children,
  actions,
  maxWidth = "md",
}) {
  const styles = useStyles();

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby={labelledby}
      open={open}
      fullWidth={true}
      maxWidth={maxWidth}
      PaperProps={{ style: { borderRadius: "10px" } }}
    >
      <DialogTitle disableTypography className={styles.dialogTitle}>
        {icon}
        <Typography component={"h2"} className={styles.dialogTextTitle}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          {buttonCloseText}
        </Button>
        {actions}
      </DialogActions>
    </Dialog>
  );
}
BaseDialog.propTypes = {
  labelledby: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  maxWidth: PropTypes.string,
  open: PropTypes.bool.isRequired,
  buttonCloseText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.element),
};
