import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import api from "../service/api";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;


function getPermissions() {
    const role = CryptoJS.AES.decrypt(Cookies.get('permissions_bpf'), SECRET_KEY).toString(CryptoJS.enc.Utf8);
    return role;
}
function validatePermission() {
    const url = window.location.hostname;
    var url_splt = url.split(".");
    api.get("/isUserInEnterprise", {
        params: {
            domain: url_splt[0]
        }
    })
}

export { getPermissions, validatePermission };