import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

const BPF_SERVER_URL = process.env.REACT_APP_BPF_SERVER_URL;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export default {
    // called when the user attempts to log in
    login: ({ email, password }) => {
        const url = window.location.hostname;
        var url_splt = url.split('.');
        const request = new Request(`${BPF_SERVER_URL}/api/user/login`, {
            method: 'POST',
            body: JSON.stringify({ email: email, password, remember_me: true, domain: url_splt[0] }),
            headers: new Headers({
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }),
        });
        // accept all username/password combinations
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(async ({ data }) => {            
                await Cookies.set('access_token', data.result.token);
                const encryptedName = CryptoJS.AES.encrypt(data.result.name, SECRET_KEY).toString();
                await Cookies.set('name_bpf', encryptedName);
                const encryptedTypeUser = CryptoJS.AES.encrypt(JSON.stringify(data.result.type_user),SECRET_KEY).toString();
                await Cookies.set('permissions_bpf', data.result.type_user != null ? encryptedTypeUser : "[]"); 
              
                return Promise.resolve();
            });
    },
    logout: () => {
        Cookies.remove('name_bpf');
        Cookies.remove('permissions_bpf');
        Cookies.remove('access_token');
        return Promise.resolve();
    },
    getPermissions: () => {
        const role = CryptoJS.AES.decrypt(Cookies.get('permissions_bpf'), SECRET_KEY).toString(CryptoJS.enc.Utf8);
        return role ? Promise.resolve(role) : Promise.reject();
    },
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            Cookies.remove('name_bpf');
            Cookies.remove('permissions_bpf');
            Cookies.remove('access_token');;
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return Cookies.get('access_token')
            ? Promise.resolve()
            : Promise.reject();
    },

};