import React, { useEffect, useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Autocomplete, Skeleton } from "@material-ui/lab";
import api from '../../../service/api';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import { withStyles, makeStyles } from '@material-ui/core';
import QrCode from './QrCode';

const QRCODE_URL = process.env.REACT_APP_BPF_QRCODE_URL;

function DialogQrCode({ handleClose, handleCloseSave }) {
    const qrRef = useRef();

    const [open, setOpen] = useState(false);
    const [identifier, setIdentifier] = useState(false);
    const [itemsIdentifier, setItemsIdentifier] = useState([]);
    const [identifierSeleted, setIdentifierSeleted] = useState(null);
    const [forms, setForms] = useState([]);
    const [form, setForm] = useState(null);
    const [qrCodeValue, setQrCodeValue] = useState(null);
    const [url, setUrl] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        getForms()
    }, []);

    const changeForms = (form) => {
        setForm(form)
    }

    const getForms = () => {
        const url = window.location.hostname;
        var url_splt = url.split(".");
        api.get("/formsByEnterprise", {
            params: {
                domain: url_splt[0]
            }
        }).then(resp => {
            setForms(resp.data)
        })
    }

    const getLayout = (form_id) => {
        api.get("/layouts", {
            params: {
                form: form_id
            }
        }).then(resp => {
            let options = JSON.parse(resp.data.options)
            if (options.filter(item => item.identifier).length > 0) {
                let items = options.filter(item => item.identifier)[0].options
                setItemsIdentifier(items)
            }
        })
    }
    const handleIndetifier = (event) => {
        setIdentifier(event.target.checked)
    }
    const handleSelectIdentifier = (event) => {
        setIdentifierSeleted(event.target.value)
    }

    const generateQRCode = () => {
        const url_host = window.location.hostname;
        var url_splt = url_host.split(".");
        let url = `${QRCODE_URL}/?form_id=${form.id}&domain=${url_splt[0]}&identifier=${identifierSeleted}`
        setUrl(url);
    }
    const handleDownload = async () => {
        const canvas = await qrRef.current.querySelector('canvas');
        const pngUrl = await canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = `${identifierSeleted}.png`;
        document.body.appendChild(downloadLink);
        await downloadLink.click();
        document.body.removeChild(downloadLink);
        handleCloseSave()
        return true;
    };

    const handleSave = () => {
        if (url) {
            const url = window.location.hostname;
            var url_splt = url.split(".");
            api.post("/form_qrcodes", {
                identifier: identifierSeleted,
                form_id: form.id,
                domain: url_splt[0],
            }).then(resp => {
                handleDownload()
            })
        } else {
            alert("Gere o QRCODE");
        }

    }
    return (
        <Dialog
            open={true}
            maxWidth="md"
            fullWidth
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Qr Code</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Selecione o formulario que deseja gerar o QrCode
                </DialogContentText>
                <Autocomplete
                    style={{ width: '90%', margin: '10px 10px 10px 0px' }}
                    id="forms-standard"
                    noOptionsText={'Não há formulários.'}
                    options={forms}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                        changeForms(value)
                        if (value) {
                            getLayout(value.id)
                        }
                    }}
                    value={form}
                    defaultValue={null}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            fullWidth
                            variant="standard"
                            label={`Formulários`}
                            placeholder="Adicione formulários"
                        />
                    )}
                    renderTags={(value) => value.map((option, index) => {
                        return (
                            <span style={{
                                padding: 6,
                                maxWidth: '18ch',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}> {`${option.name}, `} </span>
                        )
                    })}
                />
                <FormControlLabel
                    className={classes.formControlLabel}
                    control={<Switch checked={identifier} disabled={form == null} onChange={handleIndetifier} />}
                    label="Identificador"
                />
                {
                    identifier && <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">
                            Selecione seu identificador do QRCode
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={identifierSeleted}
                            onChange={(e) => handleSelectIdentifier(e)}
                        >
                            <MenuItem value={""}></MenuItem>
                            {itemsIdentifier.map((item, index_component) => {
                                return <MenuItem value={item.label}>{item.label}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                }

                <Button onClick={generateQRCode} color="primary">
                    Gerar QRCode
                </Button>

                {url &&
                    <div className={classes.qrContainer} ref={qrRef}>
                        <QrCode url={url} />
                    </div>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        minWidth: 350,
    },
    formControlLabel: {
        marginTop: theme.spacing(2),
    },
    qrContainer: {
        textAlign: "center"
    }
}));

export default DialogQrCode