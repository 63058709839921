import React from "react";
import {
  TextField,
  Button,
  withStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import api from "../../../service/api";
import DialogError from "../../alertas/DialogError";

class InsertEnterprise extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      nome: "",
      localidade: "",
      showError: false,
    };
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  save = async () => {
    try {
      const formData = new FormData();
      formData.append("file", this.state.file);
      formData.append("domain", this.state.domain);
      api
        .post("/storeLogo", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((respLogo) => {
          api
            .post("/enterprises", {
              name: this.state.name,
              cnpj: this.state.cnpj,
              domain: this.state.domain,
            })
            .then(() => {
              this.props.save();
            });
        });
    } catch (error) {
      var error_message = {
        title: "Erro ao alterar senha do empresa",
        subTitle: error.message,
      };
      this.setState({
        showError: true,
        error: error_message,
      });
    }
  };

  handleFileSelect = (event) => {
    this.setState({ file: event.target.files[0] });
  };
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.state.open}
        onClose={() => this.props.close()}
        aria-labelledby="form-dialog-title"
        maxWidth={"sm"}
        fullWidth={true}
      >
        <DialogError
          open={this.state.showError}
          onClose={() => this.setState({ showError: false })}
          title={
            this.state.error ? this.state.error.title : "Erro ao salvar senha"
          }
          message={
            this.state.error
              ? this.state.error.subTitle
              : "Ao salvar, vimos que não contém conteúdo na senha."
          }
          suggestion={""}
        />
        <DialogTitle id="form-dialog-title">Inserir Usuario</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              className={classes.text}
              margin="dense"
              type="text"
              id="name"
              label="Nome"
              value={this.state.name}
              onChange={this.handleChange("name")}
            />
            <TextField
              className={classes.text}
              margin="dense"
              id="cnpj"
              label="CNPJ"
              value={this.state.cnpj}
              onChange={this.handleChange("cnpj")}
            />
            <TextField
              className={classes.text}
              margin="dense"
              id="domain"
              label="Dominio"
              value={this.state.domain}
              onChange={this.handleChange("domain")}
            />
            <TextField
              className={classes.text}
              margin="dense"
              type="file"
              id="signature"
              label="Logo"
              onChange={this.handleFileSelect}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.close()} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => this.save()} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = (theme) => ({
  formControl: {
    width: 200,
    margin: "13px",
  },
  text: {
    margin: theme.spacing(2),
  },
  textId: {
    margin: theme.spacing(2),
    width: 40,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
    display: "flex",
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -4,
    left: -8,
    zIndex: 1,
  },
  searchButton: {
    marginTop: "20px",
  },
});

export default withStyles(styles)(InsertEnterprise);
