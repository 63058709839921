import { Paper } from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import api from "../../../service/api";
import NovoUsuario from "./newUsuario";
import SuccessAlert from "../../alertas/SuccessAlert";
import AlterPassword from "./alterPassword";
import { validatePermission } from "../../../libs/permissions";

class Usuario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: "id", field: "id", hidden: true },
        { title: "Nome", field: "name" },
        { title: "Email", field: "email" },
        {
          title: "Tipo",
          field: "iduser_type",
          lookup: {
            1: "Suporte",
            2: "Administrador",
            3: "Operador",
            4: "Monitor",
            5: "Verificador",
            6: "Supervisor",
          },
        },
        // {
        //     title: 'Status', field: 'status', lookup: { 0: 'Inativo', 1: 'Ativo' },
        // },
      ],
      data: [],
      loading: false,
      novoUsuario: false,
      messageSuccess: "",
      userId: null,
    };
  }
  componentDidMount() {
    validatePermission();
    this.getUsuario();
  }

  getUsuario = () => {
    const url = window.location.hostname;
    var url_split = url.split(".");
    api.get(`/getDomain?domain=${url_split[0]}`).then((resp_enterprise) => {
      var enterprise_id = resp_enterprise.data.data.result.id;
      api.get(`/getUsers/${enterprise_id}`).then((users) => {
        this.setState({ data: users.data });
      });
    });
  };

  saveUsuario = () => {
    this.getUsuario();
    this.setState({
      novoUsuario: false,
      messageSuccess: "Usuário salvo com sucesso",
    });
  };
  savePassword = () => {
    this.getUsuario();
    this.setState({
      alterPassword: false,
      messageSuccess: "Senha Alterada com sucesso",
      userId: null,
    });
  };
  render() {
    return (
      <Paper>
        {this.state.novoUsuario && (
          <NovoUsuario
            close={() => this.setState({ novoUsuario: false })}
            save={() => this.saveUsuario()}
          />
        )}
        {this.state.alterPassword && (
          <AlterPassword
            user={this.state.userId}
            close={() => this.setState({ alterPassword: false })}
            save={() => this.savePassword()}
          />
        )}
        <SuccessAlert
          open={this.state.messageSuccess !== ""}
          message={this.state.messageSuccess}
          onClose={() => this.setState({ messageSuccess: "" })}
        />
        <MaterialTable
          title="Usuários"
          columns={this.state.columns}
          data={this.state.data}
          isLoading={this.state.loading}
          actions={[
            {
              icon: "add",
              tooltip: "Add  usuario",
              onClick: () => this.setState({ novoUsuario: true }),
              isFreeAction: true,
            },
            {
              icon: "key",
              tooltip: "Alterar Senha",
              onClick: (event, rowData) =>
                this.setState({ alterPassword: true, userId: rowData.id }),
            },
          ]}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataUpdate = [...this.state.data];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  api
                    .put(`/user/${oldData.id}`, {
                      name: dataUpdate[index].name,
                      email: dataUpdate[index].email,
                      iduser_type: dataUpdate[index].iduser_type,
                    })
                    .then((resp) => {
                      this.setState({ data: dataUpdate });
                      resolve();
                    });
                }, 1000);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  const dataDelete = [...this.state.data];
                  const index = oldData.tableData.id;
                  api.delete(`/user/${oldData.id}`).then((resp) => {
                    dataDelete.splice(index, 1);
                    this.setState({ data: dataDelete });
                  });
                  resolve();
                }, 1000);
              }),
          }}
        />
      </Paper>
    );
  }
}

export default Usuario;
