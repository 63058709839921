import React from "react";
import {
  Paper,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  Fab,
  CircularProgress,
  CardHeader,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DialogForm from "./DialogForm";
import Form from "./Forms";
import api from "../../service/api";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ConfirmDialog from "../alertas/Confirm";
import { getPermissions, validatePermission } from "../../libs/permissions";

class FormLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      forms: [],
      openDialogInsert: false,
      openForm: false,
      typeForm: props.options.type,
      titlePop: props.options.label,
      anchorEl: null,
      form_atual: null,
      permissions: [],
      title: "",
      body: "",
    };
  }

  componentDidMount() {
    validatePermission();
    this.getTypeForms();
    this.setPermission();
  }
  setPermission = () => {
    var permissions = JSON.parse(getPermissions());
    this.setState({ permissions });
  };
  getTypeForms = () => {
    this.setState({ loading: true });
    const url = window.location.hostname;
    var url_splt = url.split(".");
    api
      .get("/getType", {
        params: {
          type: this.state.typeForm,
          domain: url_splt[0],
        },
      })
      .then((resp) => {
        this.setState({ forms: resp.data, loading: false });
      });
  };
  handleClickOpen = () => {
    this.setState({ openDialogInsert: true });
  };
  handleCloseDialog = () => {
    this.setState({ openDialogInsert: false });
  };
  handleCloseDialogSave = () => {
    this.setState({ openDialogInsert: false });
    this.getTypeForms();
  };
  handleClick = (event, form) => {
    this.setState({
      openMenu: !this.state.openMenu,
      anchorEl: event.currentTarget,
      form_atual: form,
    });
  };
  handleClose = () => {
    this.setState({
      openMenu: false,
      anchorEl: null,
      form_atual: null,
      openDialogConfirmDelete: false,
    });
  };
  handleItem = (event, index) => {
    var form = this.state.form_atual;
    if (index == 1) {
    } else {
      this.setState({ openDialogInsert: true });
    }
  };
  deleteForm = () => {
    var form = this.state.form_atual;
    api.delete(`/forms/${form.id}`).then((resp) => {
      this.getTypeForms();
      this.handleClose();
    });
  };
  handleDelete = () => {
    this.setState({
      openDialogConfirmDelete: true,
      title: "Atenção",
      body: "Deseja deletar o formulário?",
    });
  };
  render() {
    const { classes } = this.props;

    if (this.state.openForm)
      return (
        <Form
          labelForm={this.state.labelForm}
          form_id={this.state.form_id}
          close={() => this.setState({ openForm: false })}
        />
      );
    else
      return (
        <Paper className={classes.container}>
          {this.state.openDialogInsert && (
            <DialogForm
              form={this.state.form_atual}
              handleClose={this.handleCloseDialog}
              handleCloseSave={this.handleCloseDialogSave}
              type={this.state.typeForm}
            />
          )}
          {this.state.openDialogConfirmDelete && (
            <ConfirmDialog
              title={this.state.title}
              body={this.state.body}
              handleCloseConfirmT={this.deleteForm}
              handleCloseConfirmF={this.handleClose}
            />
          )}
          <div>
            <h1>{this.state.titlePop}</h1>
          </div>
          {this.state.permissions?.name === "SUPORTE" && (
            <div className={classes.action}>
              <Fab
                color="primary"
                aria-label="add"
                onClick={this.handleClickOpen}
              >
                <AddIcon />
              </Fab>
            </div>
          )}
          {this.state.loading && (
            <div className={classes.containerProgress}>
              <CircularProgress />
            </div>
          )}
          <div className={classes.cardContainer}>
            {this.state.forms.map((form) => (
              <Card className={classes.rootCard}>
                {this.state.permissions?.name == "SUPORTE" && (
                  <>
                    <CardHeader
                      action={
                        <IconButton
                          aria-label="settings"
                          onClick={(event) => this.handleClick(event, form)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      }
                      classes={{ title: classes.contentHeader }}
                    />

                    <Menu
                      id="long-menu"
                      anchorEl={this.state.anchorEl}
                      // keepMounted
                      open={this.state.openMenu}
                      onClose={this.handleClose}
                    >
                      <MenuItem
                        key={"Editar"}
                        onClick={(event) => this.handleItem(event, 0)}
                      >
                        {"Editar"}
                      </MenuItem>
                      <MenuItem
                        key={"Excluir"}
                        selected={"Excluir" === "Pyxis"}
                        onClick={() => this.handleDelete(form)}
                      >
                        {"Excluir"}
                      </MenuItem>
                    </Menu>
                  </>
                )}
                <CardContent>
                  <Typography variant="h5" component="h2">
                    {form.name}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() =>
                      this.setState({
                        openForm: true,
                        labelForm: form.name,
                        form_id: form.id,
                      })
                    }
                  >
                    Visualizar
                  </Button>
                </CardActions>
              </Card>
            ))}
          </div>
        </Paper>
      );
  }
}

const styles = (theme) => ({
  container: {
    padding: 20,
  },
  cardContainer: {
    padding: 20,
    display: "flex",
    flexWrap: "wrap",

    // gap: '5%',
    // cursor: 'pointer'
  },
  rootCard: {
    width: "275px",
    margin: "10px",
    // border: '1px solid #ddd',
    // // borderRadius: '5px',
    // boxShadow: '1px 2px 1px #ddd'
  },
  action: {
    padding: "5px",
    justifyContent: "end",
    display: "flex",
  },
  containerProgress: {
    textAlign: "center",
  },
  contentHeader: {
    fontSize: "16px",
    textAlign: "center",
  },
});

export default withStyles(styles)(FormLayout);
