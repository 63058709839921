import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import api from "../../service/api";

function Audit() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAudits()
    }, []);

    const getAudits = () => {
        setLoading(true)
        const url = window.location.hostname;
        let url_split = url.split(".");
        api.get(`change-requests/${url_split[0]}`).then(resp => {
            setLoading(false)
            setData(resp.data)
        })
    }
    const approve = (data_form) => {
        api.post(`change-requests/${data_form}/approve`).then(resp => {
            getAudits()
        })
    }
    const reject = (data_form) => {
        api.post(`change-requests/${data_form}/reject`).then(resp => {
            getAudits()
        })
    }
    return (<Paper>
        <div style={{ maxWidth: '100%' }}>
            <MaterialTable
                isLoading={loading}
                columns={[
                    {
                        title: 'Data', field: 'created_at', render: rowData => {
                            const utcDate = new Date(rowData.created_at);
                            const localDate = utcDate.toLocaleString(); // Converte para o horário local
                            return localDate;
                        }
                    },
                    { title: 'ID Form', field: 'data_form_id' },
                    { title: 'Comentario', field: 'comment' },
                    { title: 'ID Solicitação', field: 'id', hidden: true },
                    { title: 'Status', field: 'status', lookup: { "pending": 'Pendente', "approved": 'Aprovado', "rejected": 'Recusado', "finished": 'Finalizado' } },
                    { title: 'Solicitado por', field: 'requested_by.name' },
                    { title: 'Respondido por', field: 'supervisor.name' },
                    {
                        title: 'Data resposta', field: 'approved_at', render: rowData => {
                            if (rowData.approved_at != null) {
                                const utcDate = new Date(rowData.approved_at);
                                const localDate = utcDate.toLocaleString(); // Converte para o horário local
                                return localDate;
                            }

                        }
                    },
                ]}
                actions={[
                    rowData => ({
                        icon: 'check',
                        tooltip: 'Aprovar',
                        onClick: (event, rowData) => approve(rowData.id),
                        disabled: rowData.status != "pending"
                    }),
                    rowData => ({
                        icon: 'cancel',
                        tooltip: 'Recusar',
                        onClick: (event, rowData) => reject(rowData.id),
                        disabled: rowData.status != "pending"
                    })

                ]}
                data={data}
                title="Solicitações de auditoria"
            />
        </div>
    </Paper>)
}

export default Audit