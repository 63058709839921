import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/core";
import Dropzone from "react-dropzone-uploader";
import "../styles/dropzone.css";
import api from "../../service/api";

class AddAssinatura extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      files: this.props.files,
      file: "",
      files_send: "",
      trava: false,
      alteracao: this.props.alteracao,
      cont: this.props.cont,
      blob: this.props.blob,
      desabilitado: false,
    };
  }

  // alerta = () => {
  //     return <Alerta alert={this.state.alert} type={this.state.type} text={this.state.text} temp={this.state.temp} close={() => this.setState({ alert: false })} />
  // }

  handleClose = async () => {
    await this.props.handleClose();
  };

  componentDidMount = async () => {
    // await this.state.alteracao == true && this.handleAssinaturas(this.props.user);
    // if (this.props.files != undefined) {
    //     if (this.props.files.length > 0) {
    //         if (this.state.files == undefined) {
    //             this.setState({ file: undefined, files: undefined })
    //         } else {
    //             if (this.props.files.previewUrl != undefined && this.state.files != undefined) {
    //                 this.setState({ trava: true })
    //                 await this.handleFile();
    //             }
    //         }
    //     }
    // }
  };

  handleAssinaturas = (user) => {
    this.setState({ trava: true });
    api
      .put(`/physiciansUpdate/${user}`, {
        user: user,
      })
      .then(async (respPhysician) => {
        let id_physician = respPhysician.data.id;
        api
          .get(`/medicalSignature/${id_physician}`, {
            headers: { "x-content-type": "text/base64" },
          })
          .then((resp) => {
            if (
              this.props.files != undefined &&
              this.props.files != "" &&
              this.props.files != []
            ) {
              fetch(this.props.files.previewUrl).then((res) => {
                res.arrayBuffer().then((buf) => {
                  const file = new File([buf], "Sem_Imagem", {
                    type: "image/jpeg",
                  });
                  this.setState({ file, trava: false, loading: false });
                });
              });
            } else {
              this.setState({ file: `data:image/jpeg;base64,${resp.data}` });

              fetch(`data:image/jpeg;base64,${resp.data}`).then((res) => {
                res.arrayBuffer().then((buf) => {
                  const file = new File([buf], "Sem_Imagem", {
                    type: "image/jpeg",
                  });
                  this.setState({ file, trava: false, loading: false });
                });
              });
            }
          })
          .catch((error) => {
            this.setState({
              loading: false,
              alert: true,
              type: "error",
              text: `${error.message}`,
              temp: 5000,
              desabilitado: true,
            });
          });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          alert: true,
          type: "error",
          text: `${error.message}`,
          temp: 5000,
          desabilitado: true,
        });
      });
  };

  handleFile = async () => {
    fetch(this.props.files.previewUrl).then((res) => {
      res.arrayBuffer().then((buf) => {
        const file = new File([buf], { type: "image/jpeg" });
        this.setState({ file });
      });
    });
  };

  handleSalvar = async () => {
    this.setState({ loading: true });
    const formData = new FormData();
    formData.append("file", this.state.file_blob);
    formData.append("data_form", this.props.id_data_form);
    try {
      await await api
        .post("/storeFile", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((resp) => {
          this.setState({ loading: false });
          this.props.handleCloseSave(resp.data.file);
        });
    } catch (error) {
      console.log(error);
      this.setState({ loading: false });
    }
    // this.props.handleCloseSave()
    // this.setState({ alert: true, type: 'success', text: 'Assinatura Salva', temp: 1000, trava: true });
    // setTimeout(() => this.handleClose(), 1000)
  };

  removeFiles = () => {
    this.setState({ desabilitado: true });
    // this.props.removeFiles()
    this.setState({ file: undefined });
  };

  // chamado toda vez que o 'status' de um arquivo muda
  handleChangeStatus = ({ meta, file }, status) => {
    //'done' quer dizer que o arquivo foi preparado e validado
    if (status == "done") {
      this.setState({ file: meta, file_blob: file, desabilitado: false });
      if (this.props.cont == 0) {
        this.setState({ blob: this.state.file.previewUrl, cont: 1 });
        // this.props.returnBlob()
      }
      if (this.state.file.length > 0) {
        if (this.state.file.previewUrl == this.props.files.previewUrl) {
          this.setState({ file: this.props.files });
        }
      }

      this.setState({ trava: false });
    }
    //'removed' quer dizer que o arquivo for removido do ponto de lençamento/campo de assinatura
    if (status == "removed") {
      this.removeFiles();
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          style={{ borderRadius: "10px", zIndex: "1000" }}
          fullWidth={true}
          maxWidth={"sm"}
          open={true}
          onClose={() =>
            this.setState({
              alert: true,
              type: "error",
              text: "Salve a assiantura antes de sair",
              temp: 1000,
              trava: true,
            })
          }
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle
            className={classes.DialogTitle}
            id="max-width-dialog-title"
          >
            Adicionar anexos
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <p className={classes.subTitle}>
              Adicionar algum arquivo que estará constando no formulario
            </p>
            <p className={classes.subTitle}>
              Clique no campo para adicionar ou substituir o arquivo
            </p>
            <div style={{ width: "96%" }}>
              <div className={classes.TextFieldMargin}>
                <Dropzone
                  addClassNames={"dzu-dropzone"}
                  maxFiles={1}
                  inputContent="clique para anexar a documento"
                  onChangeStatus={this.handleChangeStatus}
                  accept="image/*"
                  canCancel={false}
                  canRestart={false}
                  canRemove={true}
                  SubmitButtonComponent={null}
                  initialFiles={[this.state.file]}
                ></Dropzone>
              </div>
            </div>

            <div className={classes.Divbutons}>
              <Button
                onClick={() => this.handleClose()}
                className={classes.button}
              >
                Voltar
              </Button>
              <Button
                disabled={this.state.desabilitado}
                onClick={() => this.handleSalvar()}
                className={classes.button}
              >
                {this.state.loading && (
                  <CircularProgress
                    className={classes.icon}
                    size={18}
                    thickness={2}
                  />
                )}
                Salvar
              </Button>
            </div>
            {/* {this.state.alert && this.alerta()} */}
          </DialogContent>
          {/* {this.state.trava == true && <CircularProgress size={"6em "} className={classes.loading} />} */}
        </Dialog>
      </div>
    );
  }
}
const useStyles = (theme) => ({
  dialogContent: {
    padding: "8px 0px 0px 24px",
    "& .MuiInputBase-root": {
      fontSize: "12px",
    },
  },
  DialogTitle: {
    "& .MuiTypography-h6": {
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "21.09px",
      color: "#0D3C61",
    },
  },
  subTitle: {
    color: "#0078F0",
    fontSize: "12px",
  },
  Divbutons: {
    textAlign: "end",
    marginTop: "2em",
    marginBottom: "0.5em",
  },
  button: {
    fontSize: "10px",
    color: "#0078F0",
    marginRight: theme.spacing(1),
  },
  fab: {
    margin: theme.spacing(2),
  },
  formControl: {
    width: "200px",
    margin: "10px",
  },
  TextField2: {
    width: "200px",
    margin: "10px",
  },
  titleAndAddIcon: {
    display: "flex",
    justifyContent: "space-between",
  },
  divFormControl: {
    display: "flex",
    justifyContent: "space-between",
  },
  // loading: {
  //     position: "absolute",
  //     zIndex: 1000000,
  //     display: "flex",

  // }
});

export default withStyles(useStyles)(AddAssinatura);
