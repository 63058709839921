import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
} from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/core";
import "../styles/dropzone.css";

class DialogEditInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputName: "",
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Dialog
          style={{ borderRadius: "10px", zIndex: "10000" }}
          fullWidth={true}
          maxWidth={"sm"}
          open={true}
          onClose={() => this.props.close()}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle
            className={classes.DialogTitle}
            id="max-width-dialog-title"
          >
            Alterar nome do Input
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <TextField
              className={classes.input}
              label={"Título do campo"}
              value={this.state.label}
              onChange={(event) =>
                this.setState({ inputName: event.target.value })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => this.props.updateNameInput(this.state.inputName)}
            >
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const useStyles = (theme) => ({
  dialogContent: {
    padding: "8px 0px 0px 24px",
    "& .MuiInputBase-root": {
      fontSize: "12px",
    },
  },
  DialogTitle: {
    "& .MuiTypography-h6": {
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "21.09px",
      color: "#0D3C61",
    },
  },
  input: {
    width: "100%",
  },
});

export default withStyles(useStyles)(DialogEditInput);
