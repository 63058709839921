import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    TextField,
    makeStyles, FormControl, MenuItem, InputLabel, Select
} from "@material-ui/core";
import api from "../../../service/api";

function RequestAuditAdmin({ handleClose, handleCloseSave, dataFormId }) {
    const [comment, setComment] = useState("");
    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState(null);
    const classes = useStyles();

    const handleChange = (event) => {
        setComment(event.target.value);
    };
    useEffect(() => {
        getUsers()
    }, [])
    const getUsers = () => {
        const url = window.location.hostname;
        var url_split = url.split(".");
        api.get(`/getDomain?domain=${url_split[0]}`).then((resp_enterprise) => {
            var enterprise_id = resp_enterprise.data.data.result.id;
            api.get(`/getUsers/${enterprise_id}`).then((users) => {
                setUsers(users.data)
            });
        });
    }
    const saveComment = () => {
        const url = window.location.hostname;
        let url_split = url.split(".");
        api.post(`change-requests/${dataFormId}/requested-for`, {
            comment,
            domain: url_split[0],
            requested: userSelected
        }).then(resp => {
            handleCloseSave()
        })
    }
    return (<Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={true}
        onClose={() => handleClose()}
        aria-labelledby="max-width-dialog-title"
    >
        <DialogTitle
            className={classes.DialogTitle}
            id="max-width-dialog-title"
        >
            Descreva o motivo da edição
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">
                    Usuário
                </InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userSelected}
                    onChange={(e) => {
                        setUserSelected(e.target.value)
                    }}
                    inputProps={{
                        name: "group-sentence",
                        id: "group-sentence-native-simple",
                    }}
                >
                    {users.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                            {user.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                id="outlined-multiline-static"
                label="Comentário"
                multiline
                defaultValue="Comentário"
                variant="outlined"
                onChange={handleChange}
                value={comment}
                className={classes.textComment}
            />
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => handleClose()}
                className={classes.buttonCancel}
            >
                Cancelar
            </Button>
            <Button
                onClick={() => saveComment()}
                className={classes.buttonSave}
            >
                Salvar e Enviar
            </Button>
        </DialogActions>
    </Dialog>)
}

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        padding: "8px 0px 0px 24px",
        "& .MuiInputBase-root": {
            fontSize: "12px",
        },
    },
    DialogTitle: {
        "& .MuiTypography-h6": {
            fontSize: "18px",
            fontWeight: "700",
            lineHeight: "21.09px",
            color: "#0D3C61",
        },
    },
    textComment: {
        width: "98%",
    },
    buttonSave: {
        color: "white",
        background: "#2E8B57"
    },
    buttonCancel: {
        color: "white",
        background: "#D04A50"
    },
    formControl: {
        width: "200px",
        margin: "10px",
    },
}));
export default RequestAuditAdmin