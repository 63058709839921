import { Paper } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import api from "../../service/api";

function Report() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getReports()
    }, []);

    const getReports = () => {
        setLoading(true)
        api.get(`data-form/reports`).then(resp => {
            setLoading(false)
            setData(resp.data)
        })
    }

    return (<Paper>
        <div style={{ maxWidth: '100%' }}>
            <MaterialTable
                isLoading={loading}
                columns={[
                    { title: 'Empresa', field: 'enterprise' },
                    { title: 'Quantidade', field: 'quantity' },
                    { title: 'ID Empresa', field: 'enterprise_id' },
                ]}
                data={data}
                title="Relatório de formulários"
            />
        </div>
    </Paper>)
}

export default Report