import axios from 'axios';
import Cookies from 'js-cookie';
const BASE_URL = process.env.REACT_APP_BPF_SERVER_URL;

const api = axios.create({
    baseURL: `${BASE_URL}/api/bpf/`,
    transformRequest: [(data, headers) => {
        headers['Authorization'] = `Bearer ${Cookies.get('access_token')}`
        return data
    }, ...axios.defaults.transformRequest],
})

api.interceptors.response.use(response => response, error => {
    if (error.response) {
        const code = error.response.status
        const response = error.response.data

        if (code === 401) {
            setTimeout(async () => {
                await localStorage.clear()
                await Cookies.remove('name_bpf');
                await Cookies.remove('permissions_bpf');
                await Cookies.remove('access_token');;
                await window.location.assign('#/Login')
            }, 2000)
        }

        return Promise.reject(response)
    }
})

export default api;