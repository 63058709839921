import React from 'react';
import { Resource, Labeled } from 'react-admin';
import CadastroUsuario from "./components/cadastros/Usuario";
import CadastroEmpresa from "./components/cadastros/Enterprise";
import QrCode from "./components/qrforms";
import Audit from "./components/audit";
import Report from "./components/reports";
import MyAudit from "./components/audit/MyAudits";
import FormLayout from "./components/form_layout";

export const getTropicalResources = (permission) => [
    <Resource
        key="pop02"
        name="pop02"
        list={FormLayout}
        options={{ label: "Sementes Tropical", type: "2" }}
    />,
    (permission?.name === "SUPORTE" || permission?.name === "ADMINISTRADOR") && (
        <Resource key="Usuario" name="Usuario" list={CadastroUsuario} options={{ label: "Usuario" }} />
    ),

];

export const getHeinekenResources = () => [
    <Resource key="pop01" name="pop01" list={FormLayout} options={{ label: "PORTARIA IND/REG", type: "1" }} />,
    <Resource key="pop02" name="pop02" list={FormLayout} options={{ label: "CD", type: "2" }} />,
    <Resource key="pop03" name="pop03" list={FormLayout} options={{ label: "LABORATÓRIO", type: "3" }} />,
    <Resource key="pop04" name="pop04" list={FormLayout} options={{ label: "OFICINA", type: "4" }} />,
    <Resource key="pop05" name="pop05" list={FormLayout} options={{ label: "PORTARIA PRINCIPAL", type: "5" }} />,
    <Resource key="pop06" name="pop06" list={FormLayout} options={{ label: "AUDITÓRIO", type: "6" }} />,
    <Resource key="pop07" name="pop07" list={FormLayout} options={{ label: "VIVÊNCIA", type: "7" }} />,
    <Resource key="pop08" name="pop08" list={FormLayout} options={{ label: "AMBULATÓRIO", type: "8" }} />,
    <Resource key="pop09" name="pop09" list={FormLayout} options={{ label: "RH", type: "9" }} />,
    <Resource key="pop10" name="pop10" list={FormLayout} options={{ label: "ALMOXARIFADO", type: "10" }} />,
];

export const getStandardResources = () => [
    <Resource key="pop01" name="pop01" list={FormLayout} options={{ label: "POP 01", type: "1" }} />,
    <Resource key="pop02" name="pop02" list={FormLayout} options={{ label: "POP 02", type: "2" }} />,
    <Resource key="pop03" name="pop03" list={FormLayout} options={{ label: "POP 03", type: "3" }} />,
    <Resource key="pop04" name="pop04" list={FormLayout} options={{ label: "POP 04", type: "4" }} />,
    <Resource key="pop05" name="pop05" list={FormLayout} options={{ label: "POP 05", type: "5" }} />,
    <Resource key="pop06" name="pop06" list={FormLayout} options={{ label: "POP 06", type: "6" }} />,
    <Resource key="pop07" name="pop07" list={FormLayout} options={{ label: "POP 07", type: "7" }} />,
    <Resource key="pop08" name="pop08" list={FormLayout} options={{ label: "POP 08", type: "8" }} />,
    <Resource key="pop09" name="pop09" list={FormLayout} options={{ label: "POP 09", type: "9" }} />,
    <Resource key="pop10" name="pop10" list={FormLayout} options={{ label: "POP 10", type: "10" }} />,
];

export const getNutriPuraResources = () => [
    <Resource key="pop03" name="pop03" list={FormLayout} options={{ label: "Controle de Não Conformidades", title: "TESTE", type: "3" }} />,
    <Resource key="pop04" name="pop04" list={FormLayout} options={{ label: "Manutenção Preventiva", type: "4" }} />,
    <Resource key="pop06" name="pop06" list={FormLayout} options={{ label: "Atendimento a Reclamação de clientes", type: "6" }} />,
    <Resource key="pop07" name="pop07" list={FormLayout} options={{ label: "Higiene e Saúde do colaborador", type: "7" }} />,
    <Resource key="pop08" name="pop08" list={FormLayout} options={{ label: "Qualificação de fornecedor", type: "8" }} />,
    <Resource key="pop09" name="pop09" list={FormLayout} options={{ label: "Recebimento de Matérias primas", type: "9" }} />,
    <Resource key="pop10" name="pop10" list={FormLayout} options={{ label: "Limpeza de Instalações e Equipamentos", type: "10" }} />,
    <Resource key="pop12" name="pop12" list={FormLayout} options={{ label: "Calibração de Equipamentos e balanças", type: "12" }} />,
    <Resource key="pop13" name="pop13" list={FormLayout} options={{ label: "Controle de Pragas", type: "13" }} />,
    <Resource key="pop15" name="pop15" list={FormLayout} options={{ label: "Avaliação de Produto Acabado", type: "15" }} />,
    <Resource key="pop18" name="pop18" list={FormLayout} options={{ label: "Expedição de Produto Acabado", type: "18" }} />,
    <Resource key="pop19" name="pop19" list={FormLayout} options={{ label: "Devolução de produto Acabado", type: "19" }} />,
    <Resource key="pop23" name="pop23" list={FormLayout} options={{ label: "Homogeneidade de mistura", type: "23" }} />,
    <Resource key="pop24" name="pop24" list={FormLayout} options={{ label: "Segurança do Trabalho", type: "24" }} />,
];

export const getGeneralResources = (permission) => [
    <Resource key="myaudit" name="myaudit" list={MyAudit} options={{ label: "Minhas solicitações" }} />,
    (permission?.name === "SUPORTE" || permission?.name === "ADMINISTRADOR") && (
        <Resource key="Usuario" name="Usuario" list={CadastroUsuario} options={{ label: "Usuario" }} />
    ),
    (permission?.name === "SUPORTE" || permission?.name === "ADMINISTRADOR") && (
        <Resource key="QrCode" name="QrCode" list={QrCode} options={{ label: "QR Code" }} />
    ),
    (permission?.name === "SUPORTE" || permission?.name === "ADMINISTRADOR") && (
        <Resource key="audit" name="audit" list={Audit} options={{ label: "Auditoria" }} />
    ),
    permission?.name === "SUPORTE" && (
        <Resource key="report" name="report" list={Report} options={{ label: "Relatório Forms" }} />
    ),
    permission?.name === "SUPORTE" && (
        <Resource key="Empresa" name="Empresa" list={CadastroEmpresa} options={{ label: "Empresa" }} />
    ),
];

