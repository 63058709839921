import React, { useState } from "react";
import { Notification, useLogin, useNotify } from "react-admin";

import { MuiThemeProvider, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Container } from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import CssBaseline from "@material-ui/core/CssBaseline";
const BPF_SERVER_URL = process.env.REACT_APP_BPF_SERVER_URL;

const styles = makeStyles(function (theme) {
  return {
    paper: {
      backgroundColor: "#fff",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "30px",
      color: "#023575",
      borderRadius: "15px",
      boxShadow: "1px 4px 1px #d9d9d9",
    },
    card: {
      minWidth: 300,
      marginTop: "6em",
    },

    title: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundImage: `url('./fundo2.jpg')`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },

    avatar: {
      margin: theme.spacing(1),
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      padding: "15px",
    },
    submit: {
      margin: theme.spacing(2, 0, 2),
      fontWeight: "bold",
      color: "#fff",
    },
    imgDiv: {
      width: "70%",
      height: "auto",
    },
    img: {
      width: "100%",
      padding: "10px",
    },
    imgDivFooter: {
      width: "30%",
      height: "auto",
    },
    img: {
      width: "100%",
      padding: "10px",
    },
  };
});
const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);
const MyLoginPage = ({ theme }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const classes = styles();
  var login = useLogin();
  const notify = useNotify();
  const submit = (e) => {
    e.preventDefault();
    setLoading(true);
    login({ email, password })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        notify("Login ou Senha inválidos");
        setLoading(false);
      });
  };
  const url = window.location.hostname;
  var url_splt = url.split(".");
  return (
    <div className={classes.title}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          {/* <img class="logo" src="../../../public/sbr_logo.png" /> */}
          <div className={classes.imgDiv}>
            <img
              className={classes.img}
              src={`${BPF_SERVER_URL}/showLogo?filename=${url_splt[0]}.png`}
            />
            {/* <img className={classes.img} src="./sbr_logo.png" /> */}
          </div>
          <Typography component="h1" variant="h5">
            BPF
          </Typography>
          <form className={classes.form} onSubmit={submit}>
            <TextField
              variant="outlined"
              margin="dense"
              required
              fullWidth
              id="Usuario-sbr"
              label="Usuario"
              name="email-sbr"
              type="email"
              autoComplete="Email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="dense"
              required
              fullWidth
              name="password-sbr"
              label="Senha"
              type="password"
              id="senha-sbr"
              autoComplete="current-senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {loading && (
                <CircularProgress
                  className={classes.icon}
                  size={18}
                  thickness={2}
                />
              )}
              Login
            </Button>
          </form>
          <div className={classes.imgDivFooter}>
            <img className={classes.img} src="./sbr_logo.png" />
          </div>
        </div>
      </Container>
      <Notification />
    </div>
  );
};

const LoginWithTheme = (props) => (
  <MuiThemeProvider>
    <MyLoginPage {...props} />
  </MuiThemeProvider>
);

export default LoginWithTheme;
