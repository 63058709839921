import React from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

export default function SuccessAlert({ open, message, onClose }) {
  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <Alert
        variant="filled"
        severity="success"
        iconMapping={{ success: <CheckCircleOutlineIcon /> }}
        onClose={onClose}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
